import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { ModalEditPharmacyTechnicianComponent } from '@app/modules/dashboard/modal-edit-pharmacy-technician/modal-edit-pharmacy-technician.component';
import { PrescriptionService } from '@app/modules/document/services/prescription.service';
import { HealthProfessionalService } from '@app/modules/health-professional/services/health-professional.service';
import { PatientsService } from '@app/modules/patients/services/patients.service';
import { PharmacistService } from '@app/modules/pharmacist/services/pharmacist.service';
import { PharmacyService } from '@app/modules/pharmacy/services/pharmacy.service';
import { UserService } from '@app/modules/user/user.service';
import { ModalSearchComponent } from '@app/shared/modals/modal-search/modal-search.component';
import { AdditionalFeatureAccept, HealthProfessional } from '@app/shared/models';
import { User } from '@app/shared/models/decodedLoginToken';
import { ProfessionalType } from '@app/shared/models/professional-type.enum';
import { UserType } from '@app/shared/models/user-type.enum';
import { AppToastService } from '@app/shared/services/app-toast.service';
import { StateService } from '@app/shared/services/state.service';
import * as moment from 'moment';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CroppedEvent } from 'ngx-photo-editor';
import { ReplaySubject } from 'rxjs';
import { ModalCovidComponent } from '../../modal-covid/modal-covid.component';
import { ModalNewPortalDigitalSignaturesComponent } from './../../../../shared/modals/modal-new-portal-digital-signatures/modal-new-portal-digital-signatures.component';
import { ModalComplementInfoPatientComponent } from './../../modal-complement-info-patient/modal-complement-info-patient.component';
import { nativeAsync } from '@app/shared/decorators/nativeAsync';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit, OnDestroy {
  loading = true;
  completeUser: any;
  userId: string;
  crm: string;
  modalRef: BsModalRef;
  pharmacy: any;
  functionalities = [];
  imageChangedEvent: any;
  avatarB64: any;
  pdf: any;
  filename: string;

  isModalCipVisible = false;
  isModalRqeVisible = false;
  isModalWomanVisible = false;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject();

  constructor(
    public authService: AuthService,
    private healthProfessionalService: HealthProfessionalService,
    private modalService: BsModalService,
    private patientService: PatientsService,
    private router: Router,
    private userService: UserService,
    private pharmacyService: PharmacyService,
    private pharmacistService: PharmacistService,
    private prescriptionService: PrescriptionService,
    private notification: AppToastService,
    private nzModalService: NzModalService,
    private stateService: StateService
  ) {}

  @nativeAsync
  async ngOnInit() {
    try {
      this.userService.getAvatar().subscribe(avatar => (this.avatarB64 = avatar));
      this.completeUser = await this.getCompleteUser();

      if (
        this.user.userType === UserType.PATIENT &&
        this.patientWithoutMandatoryInformationRegistered()
      ) {
        this.showModalComplementInfoPatient();
      } else {
        if (this.user.userType === UserType.PHARMACY) {
          if (!this.completeUser.technicalResponsible || !this.completeUser.technicalResponsible.cpf) {
            this.showModalEditPharmacyThechnical(this.completeUser);
          } else {
            this.openModalCovid();
          }
        }
      }

      this.checkCipAcceptance();
      if (this.completeUser.professionalType === 'doctor') {
        this.checkRQE();
      }

      if (this.completeUser.sex === 'F' && this.womensMonth && !this.hideWomensModal) {
        this.isModalWomanVisible = false;
      }
    } catch (err) {
      console.error(err);
    }
    this.loading = false;
  }

  @nativeAsync
  private async showAgainModalNewPortalDigitalSignatures() {
    if (this.user.userType === UserType.PHARMACY) {
      const {
        doNotShowAgainModalNewPortalDigitalSignatures
      } = await this.userService.getControllerUserUi(this.user._id).toPromise();
      if (!doNotShowAgainModalNewPortalDigitalSignatures) {
        this.modalService.show(ModalNewPortalDigitalSignaturesComponent, {
          backdrop: 'static',
          keyboard: false,
          class: 'modal-md'
        });
      }
    }
  }

  private patientWithoutMandatoryInformationRegistered() {
    return (
      !this.completeUser.cellphone ||
      !this.completeUser.address ||
      (this.completeUser.address &&
        !this.completeUser.address.cep &&
        !this.completeUser.address.street &&
        !this.completeUser.address.number &&
        !this.completeUser.address.neighborhood &&
        !this.completeUser.address.city &&
        !this.completeUser.address.uf)
    );
  }

  private openModalCovid() {
    if (!this.completeUser.covid) {
      this.showModalCovid(this.completeUser);
    }
  }

  get user(): User {
    return this.authService.user();
  }

  private get hideWomensModal() {
    return localStorage.getItem('hideWomensModal');
  }

  private get womensMonth(): boolean {
    return moment().month() === 2 && moment().year() === 2022;
  }

  private getCompleteUser() {
    switch (this.user.userType) {
      case 'patient':
        return this.patientService.getPatientByUserId(this.user._id).toPromise();
      case 'healthProfessional':
        return this.healthProfessionalService.getHealthProfessional(this.user._id).toPromise();
      case 'pharmacist':
        return this.getPharmacistData();
      case 'admin':
        this.router.navigate(['/admin']);
        break;
      default:
        return this.pharmacyService.getPharmacyByUserId(this.user._id).toPromise();
    }
  }

  get today() {
    return new Date();
  }

  get cipFeature() {
    return this.user && this.user.features.find(f => f.name === 'CIP');
  }

  get hasCipFeature() {
    return this.cipFeature && this.cipFeature.accept === AdditionalFeatureAccept.YES;
  }

  get hasPatientRecordFeature() {
    return this.user && this.user.features.find(f => f.name === 'Prontuário');
  }

  get hasRennovaFeature(): boolean {
    return this.user && this.user.features.some(f => f.name === 'Rennova');
  }

  get hasFarmaRennovaFeature(): boolean {
    return this.user && this.user.features.some(f => f.name === 'Farma Rennova');
  }

  private checkCipAcceptance() {
    const cipFeature = this.user.features.find(f => f.name === 'CIP');
    if (
      cipFeature &&
      cipFeature.accept !== AdditionalFeatureAccept.YES &&
      cipFeature.accept !== AdditionalFeatureAccept.NO
    ) {
      this.isModalCipVisible = true;
    }
  }

  private checkRQE() {
    const healthProfessional = <HealthProfessional>this.completeUser;
    const showModal = healthProfessional.specialties.some(s => s.notExists === undefined);
    if (showModal) {
      this.isModalRqeVisible = true;
    }
  }

  search(param: string) {
    param = param && param.replace(/[^a-z0-9]+/gi, '');
    if (param) {
      if (param.length === 11 || param.length === 15) {
        if (param.length === 15 && !this.hasFarmaRennovaFeature && param.substring(0, 3) === 'REN') {
          this.notification.notify('warning', 'Atenção', 'Código informado inválido');
        } else {
          this.requests(param);
        }
      } else {
        this.notification.notify('warning', 'Atenção', 'CPF ou código inválido');
      }
    }
  }
  @nativeAsync
  private async requests(param: string) {
    try {
      const prescriptions = await this.prescriptionService.getPrescriptionsPatient(param).toPromise();

      if (param.length === 11) {
        if (prescriptions.length === 0) {
          this.notification.notify(
            'warning',
            'Atenção',
            'Não existe prescrições com medicamentos a serem dispensados para o CPF informado'
          );
          return;
        }
        this.router.navigate(['/document/listing-prescriptions/', param], {
          state: { prescriptions: prescriptions }
        });
      } else {
        this.router.navigate(['/document/confirmation', param], {
          state: { prescription: prescriptions }
        });
      }
    } catch (error) {
      if (error.status === 404) {
        this.notification.notify('warning', 'Atenção', error.error.message);
      }
    }
  }

  get doctorName() {
    if (this.completeUser) {
      return `Dr${this.completeUser.sex === 'F' ? 'a' : ''}. ${this.completeUser.name}`;
    }
    return '';
  }
  @nativeAsync
  async getPharmacistData() {
    return await this.pharmacistService.getPharmacistByUserId(this.user._id).toPromise();
  }

  hasAccessToManipulatedFunctionality() {
    return false && this.functionalities.filter(i => i.code === 710588).length > 0;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  open() {
    this.modalRef = this.modalService.show(ModalSearchComponent, {
      backdrop: 'static',
      keyboard: false
    });
  }

  get isDermatologist() {
    return (
      this.completeUser.specialties.filter(specialty => specialty.title === 'Dermatologia').length === 1
    );
  }

  get isVeterinarian() {
    return this.completeUser.professionalType == ProfessionalType.VETERINARIAN;
  }

  showModalComplementInfoPatient() {
    const initialState = {
      patientId: this.completeUser._id,
      patient: this.completeUser
    };

    const modal = this.modalService.show(ModalComplementInfoPatientComponent, {
      class: 'modal-lg',
      initialState,
      backdrop: 'static',
      keyboard: false
    });
    modal.content.updateCompleteUser.subscribe(patient => (this.completeUser = patient));
  }

  public showModalEditPharmacyThechnical(completeUser) {
    const modal = this.nzModalService.create({
      nzContent: ModalEditPharmacyTechnicianComponent,
      nzComponentParams: {
        completeUser,
        userType: this.user.userType
      },
      nzClosable: false,
      nzMaskClosable: false,
      nzFooter: null
    });

    modal.afterClose.subscribe(() => this.openModalCovid());
  }

  showModalCovid(completeUser: any) {
    const config = {
      backdrop: false,
      keyboard: false,
      ignoreBackdropClick: true
    };

    const initialState = {
      pharmacy: completeUser
    };
    this.modalService.show(ModalCovidComponent, {
      ...config,
      initialState,
      backdrop: 'static',
      keyboard: false
    });
  }

  fileChangeEvent(event: any) {
    this.imageChangedEvent = event;
  }

  imageCropped(event: CroppedEvent) {
    this.avatarB64 = event.base64;
  }
  @nativeAsync
  async sendPdf() {
    try {
      if (this.pdf) {
        this.loading = true;
        const response = await this.prescriptionService.getPrescriptionByPdf(this.pdf);
        if (!response.thirdParties) {
          this.router.navigate(['/dispensation/consult']);
        } else {
          this.router.navigate(['/dispensation/consult-empty']);
        }
      }
    } catch (err) {
      console.error(err);
      if (err.status === 404) {
        const base64 = await this.prescriptionService.blobToBase64(this.pdf);
        this.prescriptionService.pdfBase64 = base64;
        this.prescriptionService.filename = this.filename;
        this.prescriptionService.prescription = {
          thirdParties: true,
          medicaments: [
            {
              hidden: true,
              checked: true
            }
          ]
        };
        this.router.navigate(['/dispensation/consult-not-found']);
      }
    }
    this.loading = false;
  }

  redirectDocumentNew(isRennova: boolean) {
    this.stateService.data = { isRennova };
    this.router.navigateByUrl('/document/new');
  }
}
